import { Link } from 'gatsby';
import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OutboundLink from '../components/OutboundLink';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import SubscribeBanner from '../components/SubscribeBanner';
import bgImage from '../images/img-home-about-bg.png';
import '../styles/pages/_about.scss';

const About = () => (
  <Layout title="About the Program">
    <Hero
      mediaSource="img-lead-about.jpg"
      textOverlay={{ heading: 'About the Program' }}
    />
    <Section className="about-mission">
      <Container>
        <Row>
          <Column size={6}>
            <Image
              filename="img-about-content.jpg"
              className="about__intro-img"
            />
          </Column>
          <Column size={6}>
            <h2 className="about__intro-heading">What We Believe</h2>
            <div className="about__intro-subheading text-bold h3">
              <p>
                Engineering is for <em>Everyone.</em>
              </p>
              <p>
                Engineers are needed <em>Everywhere.</em>
              </p>
            </div>
            <p className="about__intro-body">
              Our mission is to help all K-12 community stakeholders celebrate
              the “E” in STEM with equitable and empowering learning resources
              that help students and teachers become engineering enthusiasts.
              Show K-12 students the mind-blowing ways that engineering is
              enhancing our lives and fostering to a better future for Earth.
              Access a variety of interactive, standards-aligned learning
              resources created in partnership between Discovery Education and
              the American Society of Mechanical Engineers (ASME) to inspire the
              next generation of creative thinkers, risk takers, and ethical
              problem-solvers.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      {/* Education intiatives */}
      <Container className="about__initiatives">
        <Row className="pb-3">
          <Column size={12}>
            <h2>ASME Education Initiatives</h2>
            <p>
              ASME is continuously exploring new ways to connect students,
              educators, and communities to the endless problem-solving
              potential of engineering. These dynamic educational initiatives
              celebrate engineering knowledge as a practical tool for
              self-empowerment and success.
            </p>
          </Column>
        </Row>
        <Row className="pb-2 about__initiatives-info">
          <Column size={3}>
            <Image filename="logo_about_dropmein.jpg" />
          </Column>
          <Column size={9}>
            <h3>ASME DropMEIn</h3>
            <p>
              DropMEIn! is a virtual path for bringing engineers into classrooms
              to share the impact of “problem-solving for good” through the lens
              of STEM, specifically engineering. Content and discussion during
              these 30-minute live, online events is led by 1-2 professional
              engineers and/or engineering students.
            </p>
            <Link
              //to="https://www.asme.org/asme-programs/students-and-faculty/asme-k-12-stem-education-programs"
              to="/educators/request-virtual-visit"
              className="text-bold"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </Link>
          </Column>
        </Row>
        <Row className="pb-3 about__initiatives-info">
          <Column size={3}>
            <Image filename="logo_about_scholarships.jpg" />
          </Column>
          <Column size={9}>
            <h3>ASME Scholarships</h3>
            <p>
              ASME Scholarships are individual grants given to empower students
              who intend to pursue educational programs in Mechanical
              Engineering, or Mechanical Engineering technology at the
              undergraduate or graduate level. Applications for scholarships
              supporting High School graduating seniors are accepted annually
              December 1st through March 15th.
            </p>
            <Link
              to="https://www.asme.org/asme-programs/students-and-faculty/scholarships/asme-scholarships-how-to-apply"
              className="text-bold"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </Link>
          </Column>
        </Row>
        <Row className="pb-3 about__initiatives-info">
          <Column size={3}>
            <Image filename="logo_about_efest.svg" />
          </Column>
          <Column size={9}>
            <h3>E-Fests</h3>
            <p>
              ASME Engineering Festivals® (E-Fests) are a series of events that
              invite thousands of students and young engineers from around world
              to join together and celebrate engineering!
            </p>
            <Link
              to="https://efests.asme.org/"
              className="text-bold"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </Link>
          </Column>
        </Row>
        <Row className="pb-3 about__initiatives-info">
          <Column size={3}>
            <Image filename="logo_about_engineering-for-change.jpg" />
          </Column>
          <Column size={9}>
            <h3>Engineering for Change (E4C)</h3>
            <p>
              E4C’s mission is to prepare, educate, and activate the
              international engineering workforce to improve the quality of life
              of underserved communities around the world. E4C works to provide
              resources and platforms that accelerate the development of
              impactful solutions and ensure public health and safety around the
              globe.
            </p>
            <Link
              to="https://www.engineeringforchange.org/"
              className="text-bold"
              target="_blank"
              rel="noreferrer"
            >
              Learn More
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    <Container fullWidth>
      <SubscribeBanner />
    </Container>
    <Section className="pb-0 about__partners">
      {/* About the program partners */}
      <Container>
        <h2 className="pb-1">About Our Partners </h2>
        <Row verticalAlignTop className="about__partners">
          <Column size={6}>
            <Image
              filename="logo-asme-blue.svg"
              className="about__partners-logos mb-2"
            />
            <p>
              ASME promotes the art, science, and practice of multidisciplinary
              engineering and allied sciences around the globe. Founded in 1880,
              ASME is a nonprofit professional organization that enables
              collaboration, knowledge sharing, and skill development across all
              engineering disciplines, while promoting the vital role of the
              engineer in society. ASME serves a wide-ranging engineering
              community through quality learning, the development of codes and
              standards, certifications, research, conferences and publications,
              government relations, and other forms of outreach.
            </p>
          </Column>
          <Column size={6}>
            <Image
              filename="de-logo-positive.png"
              className="about__partners-logos mb-2"
            />
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in nearly 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.,
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at  
              <OutboundLink to="https://www.discoveryeducation.com">
                www.discoveryeducation.com
              </OutboundLink>
              .
            </p>
          </Column>
        </Row>
        <Row verticalAlignTop className="about__partners">
          <Column size={6}>
            <Image
              filename="logo-asme-foundation.svg"
              className="about__partners-logos mb-2"
            />
            <p>
              ASME Foundation programs form a powerful arc of support for every
              phase of a young engineer's journey, from initial inspiration and
              learning through early career engagement and nurturing first
              innovations. We believe that the great, multidisciplinary talent
              needed to create a better world must be harnessed from the full
              diversity of humanity.
            </p>
          </Column>
          <Column size={6}>
            <Image
              filename="logo-uef.png"
              className="about__partners-logos-uef mb-2"
            />
            <p>
              The United Engineering Foundation advances the engineering arts
              and sciences for the welfare of humanity. It supports engineering
              and education by, among other means, making grants.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0 about__resources">
      {/* Resources for everyone */}
      <Container
        fullWidth
        style={{ backgroundImage: `url("${bgImage}")` }}
        className="about__resources-bg-img pb-2 pt-2 pl-2 pr-2"
      >
        <h2 className="pb-1">Resources for Everyone</h2>
        <Row>
          <Column size={4} className="about__resources">
            <Image
              filename="img-about-res-think-engineer.jpg"
              className="about__resources-img"
            />
            <p className="about__resources-heading">
              Learn to Think Like an Engineer
            </p>
            <p className="about__resources-body pb-0.5">
              Give students the blueprints to use engineering in their own
              lives. The Engineering Habits of Mind is a powerful visual tool
              for teachers & learners.
            </p>
            <Button to="/engineer">Learn More</Button>
          </Column>
          <Column size={4} className="about__resources">
            <Image
              filename="img-about-res-discover-careers.jpg"
              className="about__resources-img"
            />
            <p className="about__resources-heading">
              Discover Exciting Engineering Careers
            </p>
            <p className="about__resources-body pb-0.5">
              See the many faces of engineering with interactive career
              explorations. Dive into the lives of real-world engineers working
              in industries of all kinds.
            </p>
            <Button to="/students">Learn More</Button>
          </Column>
          <Column size={4} className="about__resources">
            <Image
              filename="img-about-res-explore-topics.jpg"
              className="about__resources-img"
            />
            <p className="about__resources-heading">
              Explore Essential Engineering Topics
            </p>
            <p className="about__resources-body pb-0.5">
              Inspire students from every background with the infinite potential
              of engineering. Investigate the many ways engineering makes our
              world work.
            </p>
            <Button to="/educators/video-topic-series">Learn More</Button>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pb-0 pt-0">
      <Subfooter />
    </Section>
  </Layout>
);

export default About;
